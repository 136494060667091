/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p"
  }, _provideComponents(), props.components), {HrefLangManager, VideoIframe, LandingPageCta} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!VideoIframe) _missingMdxReference("VideoIframe", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/about-us/network/'], ['en', 'https://www.hear.com/about-us/network/'], ['en-US', 'https://www.hear.com/about-us/network/'], ['en-CA', 'https://ca.hear.com/about-us/network/']]
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "americas-top-hearing-professionals",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#americas-top-hearing-professionals",
    "aria-label": "americas top hearing professionals permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "America’s top hearing professionals"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "While it’s extremely important to invest in best-in-class-hearing aids when it comes to improving your hearing, we don’t just give them to you and hope for the best. In fact, they must be professionally programmed to make a real difference in your life. Only when you combine superior technology with first-rate professionals will you get the best outcome."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "we-work-with-only-the-best",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#we-work-with-only-the-best",
    "aria-label": "we work with only the best permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "We work with only the best"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We only work with the top 2% of hearing professionals in the country to guarantee your best results. Because your hearing needs are unique, your hearing aids are only as good as the professional who programs them based on the results from your ", React.createElement(_components.a, {
    href: "/hearing-test/",
    className: "c-md-a"
  }, "hearing test"), "."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "were-in-your-neighborhood",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#were-in-your-neighborhood",
    "aria-label": "were in your neighborhood permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "We’re in your neighborhood"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We have the largest network of hearing care professionals in the world, so no matter where you live, we can find a provider in your area. Our partners come recommended by leading specialists in the hearing aid industry and are known for their outstanding customer care."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We’re proud to say that because of their expertise, strong partnership and shared dedication to our customers, our customer satisfaction rate is over 92%!"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "listen-to-what-some-of-our-amazing-providers-have-to-say",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#listen-to-what-some-of-our-amazing-providers-have-to-say",
    "aria-label": "listen to what some of our amazing providers have to say permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Listen to what some of our amazing providers have to say"), "\n", React.createElement(VideoIframe, {
    title: "Meet our partner providers",
    width: "1089",
    height: "612.56",
    src: "https://www.youtube.com/embed/u5Z6rFdIs9o"
  }), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
